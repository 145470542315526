.result-banner {
  padding: 15px;
  background-color: #cae4c7;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  position: absolute;
  border-radius: 8px;
  z-index: 100;
  margin-top: 10px;
}

.result-banner-text {
  font-size: 14px;
  color: #32373f;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 20px;
  height: 17px;
}

.result-banner-close {
  cursor: pointer;
}
