body {
  display: flex;
  margin: 0;
  font-family: "URW DIN";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "URW DIN";
  src: local("URW DIN"),
    url(./assets/fonts/URWDIN-Regular.ttf) format("truetype");
  src: url(./assets/fonts/URWDIN-Regular.woff) format("woff"),
    /* Modern Browsers */ url(./assets/fonts/URWDIN-Regular.ttf)
      format("truetype"),
    /* Safari, Android, iOS */ url(./assets/fonts/URWDIN-Regular.woff2)
      format("woff2"); /* Legacy iOS */
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "URW DIN BOLD";
  src: local("URW DIN BOLD"),
    url(./assets/fonts/URWDIN-Demi.ttf) format("truetype");
}

@font-face {
  font-family: "URW DIN ITALIC";
  src: local("URW DIN ITALIC"),
    url(./assets/fonts/URWDIN-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "URW DIN BOLD ITALIC";
  src: local("URW DIN BOLD ITALIC"),
    url(./assets/fonts/URWDIN-DemiItalic.ttf) format("truetype");
}
