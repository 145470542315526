.event-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-list-header-no-events {
  font-size: 20px;
  margin-top: 20px;
  text-transform: capitalize;
}

.event-list-show-all-events {
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.events-list-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.events-list-title-icon {
  font-size: 27px;
}

.events-list-title-text {
  font-size: 32px;
  line-height: 39px;
}

.event-list {
  width: 100%;
  max-height: 500px;
  overflow: scroll;
}

.event-list-items {
  display: flex;
  gap: 10px;
}

.event-list-items-scroll-control {
  display: flex;
  align-items: center;
  position: relative;
}

.event-list-items-scroll-control-button {
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  cursor: pointer;
  flex: 1;
  max-width: 50px;
  align-items: center;
  position: absolute;
  z-index: 200;
  right: 30px;
  top: calc(50% - 18px);
  min-width: 30px;
}

.event-list-items-scroll-control-button-left {
  margin-right: 3px;
  left: 0;
}

.event-list-items-scroll-control-button-hidden {
  visibility: hidden;
}

.events-list-items-wrapper {
  gap: 20px;
  flex: 1;
  overflow: scroll;
  width: 100%;
  scroll-behavior: smooth;
}

.events-list-item-last {
  margin-right: 10px;
}

.events-list-items-wrapper-inline {
  display: flex;
  margin-left: 5px;
  margin-right: 30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Event list scrollbar */
.event-list::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.event-list::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background: none;
}

.event-list::-webkit-scrollbar-thumb {
  background: #888;
  //background: #e2e8f0;
  border-radius: 10px;
}

.event-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: none;
}
