@import "@Assets/styles/themedStyles.scss";

.nav-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .nav-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: $navBackgroundColor;
    width: 100%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  .nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 30px;
  }

  .nav-logo-container {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .nav-logo {
    cursor: pointer;
    aspect-ratio: auto;
    height: 40px;
  }

  .nav-links {
    list-style: none;
    padding-inline-start: 0;
    text-transform: capitalize;

    li {
      display: inline-block;
      padding: 0px 20px;
      a {
        transition: all 0.3s ease 0s;
        font-weight: 500;
        font-size: 16px;
        color: silver;
        text-decoration: none;
        font-weight: 600;

        &.active {
          color: #fff;
        }
      }

      :hover {
        color: #fff;
      }
    }
  }

  .nav-right-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    gap: 15px;
  }

  .nav-button-icon {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    &__tall {
      height: 56px;
      margin-bottom: 0px;
    }
  }

  .nav-user {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }
  .nav-user-text {
    color: #5f666d;
    text-transform: uppercase;
    text-decoration-line: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .nav-user-button {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
  }
  .toggle {
    display: none;
    color: #fff;
  }

  .nav-menu {
    display: block;
    flex: 1;
  }

  .nav-content-contanier {
    display: flex;
    height: 100%;
    min-width: 100vw;
    background-color: #eeeeee;
    justify-content: center;
  }

  .nav-sidebar {
    display: flex;
    flex-grow: 1;
  }

  .nav-content {
    flex-wrap: wrap;
    display: flex;
    text-align: left;
  }

  .nav-content-sidebar {
    padding-left: 20px;
  }

  @media only screen and (max-width: 768px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    .toggle {
      display: block;
    }
    .nav-user {
      display: none;
    }
    .nav-menu {
      display: none;
    }
    .nav-menu.nav-menu-visible {
      display: flex;
      position: absolute;
      width: 100%;
      padding: 0;
      margin: 0;
      left: 0;
      top: 30px;
      z-index: 999;
    }
    .nav-links.nav-links-visible {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #24252a;
      justify-content: center;
      display: flex;
      padding: 5px;
    }
  }
}
