.modal-content {
  display: flex;
  max-width: 75vw;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .modal-content {
    min-width: 90vw;
  }
}
