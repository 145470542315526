.notifications-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-content {
  height: calc(100vh - 289px);
}

.notifications-section-wrapper {
  display: flex;
  flex-direction: column;

  // TODO: Temp empty space to pull the footer to the bottom in the Home screen, until we start adding the content.
  height: 100%;
  width: 100vw;
  padding: 0 72px;
}

.notifications-section {
  flex: 1;
  height: 100%;
  &__title {
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    margin: 55px 0 0;
  }
  &__header {
    display: flex;
    gap: 16px;
    margin: 16px 0;
  }
  &__list {
    overflow: scroll;
    height: calc(100% - 233px);
  }
}

.notifications-section-near-you {
  padding-bottom: 20px;
  padding-right: 20px;
}
