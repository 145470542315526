.footer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  background-color: #eeeeee;
}

.trapezoid {
  width: 100%;
  background-color: #d9d9d9;
  clip-path: polygon(0 100%, 100% 2%, 100% 100%, 0 100%);
  height: 70px;
}

.footer-content {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
  background-color: #d9d9d9;
}

.footer-content-left {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
}

.footer-content-right {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
}

.footer-section {
  flex: 1;
  text-align: left;
  ul {
    text-align: left;
  }

  li {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}

.footer-mobile-logo {
  width: 15vw;
  min-width: 140px;
  max-width: 200px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .footer {
    overflow: visible;
    height: 100%;
    .footer-content {
      display: block;
      .footer-content-left {
        display: block;
      }
      .footer-content-right {
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        padding-right: 0px;
      }
    }
    .footer-section {
      text-align: center;
      ul {
        padding-inline-start: 0px;
        text-align: center;
      }
    }
  }
}
