.dropdown-menu {
  background-color: #e7e7e7;
  position: absolute;
  right: 20px;
  top: 56px;
  right: 30px;
  z-index: 1;
  min-width: 200px;
  min-height: 100px;
}

.dropdown-menu-item:not(:first-of-type) {
  padding-top: 10px;
  box-shadow: inset 0px -2px 0px #c4c4c4;
}
