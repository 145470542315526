.user-details-information-modal {
  padding-top: 30px;
  max-width: 350px;
}

.user-details-information-modal-logo {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.user-details-information-modal-text {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
}

.user-details-information-modal-buttons {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .user-details-information-modal {
    min-width: 90vw;
  }
}
