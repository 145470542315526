.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #868e96;
  background-color: #fff;
  gap: 15px;
}

.social-button-text {
  font-weight: bold;
  color: #323232;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding-top: 3px;
}

.social-button-disabled {
  opacity: 0.5;
}
