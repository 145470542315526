.event-list-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-list-item-date-label {
  font-size: 32px;
  margin-top: 20px;
}

.event-list-item-show-all-events {
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.event-list-item {
  font-family: "URW DIN";
  display: flex;
  border-style: solid;
  margin-top: 10px;
  border-radius: 5px;
  border-color: #fff;
  min-height: 100px;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  background-color: #fff;
  margin-right: 5px;
  min-height: 130px;
  a {
    color: #000;
    text-decoration: none;
    text-decoration-line: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      text-decoration-line: none;
    }
  }
}

.event-list-item-today {
  border-style: solid;
  border-color: #29aaeb;
}

.event-list-item-card {
  padding: 0px;
  border: none;
  max-width: 414px;
  margin-right: 0px;
}

.event-list-item-image-wrapper {
  position: relative;
  display: flex;
}

.event-list-item-image-loader {
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.event-list-item-image {
  width: 108px;
  height: 91px;
  border-radius: 4px;
  z-index: 100;
}

.event-list-item-image-card {
  width: 414px;
  height: 250px;
  border-radius: 4px 4px 0 0;
}

.event-list-item-image-tag {
  position: absolute;
  top: 15px;
  right: 15px;
}

.event-list-item-info {
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: 2px;
  min-height: 100px;
}

.event-list-item-info-card {
  min-width: 100%;
}

.event-list-item-date-wrapper {
  padding: 0px 25px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
}

.event-list-item-date-wrapper-card {
  margin-top: 0px;
}

.event-list-item-date-time-datetime {
  font-size: 36px;
  line-height: 35px;
}

.event-list-item-date-time-month {
  font-size: 18px;
}

.event-list-item-date-time-ext {
  font-size: 18px;
  line-height: 24px;
}

.event-list-item-details-wrapper {
  flex: 2;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.event-list-item-details-wrapper-card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.event-list-item-name {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}

.event-list-item-name-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 25px;
}

.event-list-item-detail-text {
  color: #605e5e;
  font-size: 14px;
  line-height: 20px;
}

.event-list-item-link {
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.event-list-item-state-wrapper {
  flex: 1;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.event-list-item-state-wrapper-not-logged {
  justify-content: flex-end;
}

.event-list-item-state-wrapper-card {
  padding-top: 15px;
  padding-right: 10px;
  max-width: 30px;
}

.event-list-item-state-button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  align-self: flex-end;
}

.event-list-item-fav {
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-self: flex-end;
}
