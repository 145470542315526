.button {
  width: 100%;
  padding: 16px 20px;
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-size: 14px;
  cursor: pointer;
}

.button-primary {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.button-secondary {
  border-color: #dee2e6;
  background-color: #fff;
  color: #000;
}

.button-disabled {
  opacity: 0.5;
}

.button-close {
  cursor: pointer;
  width: 60px;
  align-self: flex-end;
  background-color: transparent;
  text-align: right;
  border: none;
}
