.loader {
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.loader-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000c7;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader-modal-no-background {
  background-color: transparent;
}

.loader-horizontally-centered {
  align-items: center;
}
