.dropdown {
  position: relative;
  width: 100%;
  text-align: left;
}
.dropdown-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #334150;
}

.btn-dropdown {
  padding: 10px 20px 10px 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #c5cee0;
  border-radius: 4px;
  color: #8f9bb3;
  font-size: 1.25rem;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #fff;
}
.btn-dropdown::after {
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-right: 1.5px solid #8f9bb3;
  border-bottom: 1.5px solid #8f9bb3;
  vertical-align: 4px;
  content: "";
}
.btn-dropdown:hover {
  opacity: 0.9;
}
.nav-dropdown {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  padding: 5px 0;
  border-radius: 4px;
  background-color: #fff;
  z-index: 200;
}
.nav-dropdown ul {
  list-style-type: none;
  padding: 0px 20px;
}
.nav-dropdown ul li {
  text-align: left;
  cursor: pointer;
  padding: 5px 10px;
}
.nav-dropdown ul li button {
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.nav-dropdown ul li:hover {
  background-color: silver;
}
.nav-dropdown ul li:hover a {
  color: #fff;
}
.nav-dropdown.is-open {
  display: block;
  border-style: solid;
  border-color: #c5cee0;
  border-width: 1px;
  border-top-style: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.btn-dropdown.is-open::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  vertical-align: middle;
}

.tooltip {
  position: relative;
  display: inline-block;
  height: 22px;
  bottom: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  &.tooltip-left {
    top: -5px;
    right: 105%;
  }

  &.tooltip-top {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  &.tooltip-right {
    top: -5px;
    left: 105%;
  }

  &.tooltip-bottom {
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
