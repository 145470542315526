@import "@aws-amplify/ui-react/styles.css";

/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"); */
:root {
  --amplify-fonts-default-variable: "URW DIN BOLD";
  --amplify-fonts-default-static: "URW DIN BOLD";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-field-label-color: "#ccc";
  --amplify-components-field-large-font-size: 5px;

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
  --amplify-components-authenticator-form-padding: 0px;
}

/* TODO: Custom CSS.. */
[data-amplify-container] {
  max-width: 25vw;
  min-width: 300px;
}

.amplify-label {
  color: #334150;
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
}

.amplify-input {
  &:hover,
  &:focus,
  &:active {
    border-color: #c5cee0;
    border-radius: 3px;
    border-width: 1px;
  }
}

.amplify-input[name="username"] {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  &:hover,
  &:focus,
  &:active {
    border-color: #c5cee0;
    border-radius: 3px;
    border-width: 1px;
  }
}

.amplify-input[name="password"] {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  border-right-width: 0;
  border-top-right-radius: 0;
}

.amplify-button.amplify-field__show-password {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  .amplify-icon {
    color: #c5cee0;
    font-size: 23px;
  }
}

.amplify-button--primary {
  background-color: #000;
  &:hover,
  &:focus,
  &:active {
    background-color: #000;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.login {
  display: flex;
  height: 100%;
  .login-form {
    width: 45vw;
    min-height: 85vh;
    padding: 34px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .login-form-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .login-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;
  }

  .login-form-fields {
    margin-top: 10px;
  }

  .login-right-content {
    width: 100%;
  }

  .login-image {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background-size: cover;
    background-position: 50%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 20vw;
  }

  .login-optional-social-text {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .login-social-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }

  .login-form-register {
    text-align: center;
  }

  .login-form-register-button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    cursor: pointer;
  }

  .login-error-banner {
    display: flex;
    background-color: #ffe5e5;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-top: 20px;
    padding-right: 20px;
  }

  .login-error-banner-icon {
    margin-bottom: 5px;
    min-width: 40px;
  }

  @media only screen and (max-width: 768px) {
    .login-container {
      width: 100%;
      .login {
        flex-direction: column;
        .login-image {
          display: none;
        }
        .login-form {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        .login-form-header {
          align-items: center;
        }
      }
    }
  }
}
