.notification-item {
  text-align: start;
  padding: 16px 24px;
  background-color: #ffffff;
  box-shadow: inset 0px -2px 0px #c4c4c4;
  &__unread {
    box-shadow: none;
    outline: 3px solid #3da9ff;
    margin: 3px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;
  }
  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    flex: 1;
  }
  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  &__body {
    display: -webkit-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #605e5e;
    padding-bottom: 4px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 70px;
    max-width: 100ch;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: normal;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: auto;
      cursor: pointer;
    }
    .delete-button {
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      height: 18px;
      width: fit-content;
      padding: 0;
    }
  }
}
