.user-details-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.user-details-title {
  font-size: 20px;
  font-weight: 600;
  min-width: 400px;
}

.user-details-subtitle {
  font-size: 16px;
}

.user-details-top-section {
  margin-top: 10px;
}

.user-details-top-section-row {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.user-details-row-field {
  flex: 1;
}

.user-details-bottom-marketing {
  margin-top: 20px;
}

.user-details-bottom-disclaimer {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #333333;
}

.user-details-bottom-disclaimer-link {
  color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-decoration-line: none;
}

.user-details-save-button {
  margin-top: 20px;
  margin-bottom: 10px;
}

.user-details-wrapper::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .user-details-wrapper {
    max-width: 100%;
  }

  .user-details-top-section-row {
    flex-direction: column;
    gap: 10px;
  }

  .user-details-information-modal {
    min-width: 90vw;
  }
}
