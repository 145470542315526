.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-section-wrapper {
  display: flex;
  flex-direction: row;
  // TODO: Temp empty space to pull the footer to the bottom in the Home screen, until we start adding the content.
  height: calc(100vh - 325px);
}

.home-section {
  flex: 1;
}

.home-section-near-you {
  padding-bottom: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-section-wrapper {
    flex-direction: column;
  }
}
