.context-menu {
  background-color: #e7e7e7;
  position: absolute;
  right: 20px;
  border-radius: 5px;
  padding: 0px 20px 10px 20px;
  top: 60px;
  right: 30px;
  z-index: 1;
}

.context-menu-item {
  padding-top: 10px;
}
