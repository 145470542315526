.registration {
  display: flex;
  height: 100%;
}

.registration-form {
  width: 45vw;
  padding: 34px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.registration-form-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.registration-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.registration-subtitle {
  line-height: 19px;
  font-size: 16px;
  margin-top: 5px;
}

.registration-form-fields {
  margin-top: 10px;
}

.registration-right-content {
  width: 100%;
}

.registration-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: 50%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 20vw;
}

.registration-create-account {
  margin-top: 20px;
}

.registration-optional-social-text {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.registration-social-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.registration-error-banner {
  display: flex;
  background-color: #ffe5e5;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 20px;
  padding-right: 20px;
}

.registration-error-banner-icon {
  margin-bottom: 5px;
  min-width: 40px;
}

.registration-error-banner-link {
  border: none;
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .registration {
    flex-direction: column;
    .registration-image {
      display: none;
    }
  }
}
