.action-banner {
  padding: 10px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-banner-text {
  font-size: 14px;
  color: #fff;
  margin-bottom: 2px;
  margin-left: 5px;
}

.action-banner-text-underline {
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.action-banner-text-link {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .action-banner {
    flex-direction: column;
    border-radius: 10px;
    margin: 5px 5px;
  }

  .action-banner-text {
    font-size: 14px;
    color: #fff;
    margin-bottom: 2px;
    margin-left: 5px;
  }
}
