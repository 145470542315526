.calendar-container {
  padding: 5px 20px 20px 20px;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  input {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
}

.calendar-wrapper {
  max-width: 1350px;
  min-width: 1350px;
  // overflow: scroll;
}

.calendar-top-content {
  display: flex;
  gap: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.calendar-category-filters {
  display: flex;
  max-width: 1000px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.calendar-search {
  display: flex;
  flex: 1;
  padding: 5px;
  align-items: center;
  justify-content: flex-end;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.calendar-tags::-webkit-scrollbar {
  display: none;
}

.calendar-tags-scroll-button {
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  cursor: pointer;
}

.calendar-tags-scroll-button-icon {
  color: #323232;
}

.calendar-tags {
  scroll-behavior: smooth;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: scroll;
  padding: 10px;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */
}

.calendar-tags-all {
  width: 45px;
}

.calendar-select-wrapper {
  display: flex;
  gap: 20px;
}

.calendar-select {
  flex: 1;
}

.calendar-events-wrapper {
  flex: 1;
}

.calendar-events-row {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .calendar-events-row {
    flex-direction: column;
  }
}
