.event-details-container {
  width: 100vw;
  display: block;
  max-width: 1350px;
  padding: 0px 10px;
}

.event-details-breadcrumb {
  margin-top: 30px;
  text-align: left;
}

.event-details-detail-wrapper {
  margin-top: 30px;
  background-color: #fff;
  padding: 30px 30px;
  border-radius: 8px;
}

.event-details-text {
  font-size: 14px;
  line-height: 17px;
  color: #32373f;
}

.event-details-name {
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
}

.event-details-datetime {
  padding: 5px 0px;
}

.event-details-categories {
  display: flex;
  gap: 10px;
}

.event-details-category {
  margin-top: 20px;
}

.event-details-bottom-content {
  display: flex;
  gap: 20px;
  padding-top: 30px;
}

.event-details-description {
  min-height: 50px;
}

.event-details-bottom-content-left {
  flex: 3;
}

.event-details-bottom-content-right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.event-details-bottom-content-right-logged {
  justify-content: space-between;
}

.event-details-fav {
  margin-right: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.event-details-rsvp {
  min-width: 300px;
}

@media only screen and (max-width: 768px) {
  .event-details-detail-wrapper {
    padding: 30px 20px;
  }
  .event-details-categories {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .event-details-category {
    margin-top: 0;
  }
  .event-details-bottom-content {
    flex-direction: column;
  }
  .event-details-bottom-content-right {
    flex-direction: row;
  }
  .event-details-rsvp {
    min-width: 200px;
  }
}
