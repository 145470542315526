.tag {
  display: flex;
}

.tag-with-action {
  cursor: pointer;
}

.tag-multiple {
  margin-right: 10px;
}

.tag-item {
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  padding: 5px 20px;
  border-radius: 75px 75px;
}

.tag-item-status {
  padding: 2px 10px;
}

.tag-text {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 3px;
  white-space: nowrap;
}

.tag-text.large {
  font-size: 14px;
}
.tag-item.large {
  padding: 5px 20px;
}

.tag-text.small {
  font-size: 10px;
}
.tag-item.small {
  padding: 4px 8px;
}

.tag-text-status {
  font-size: 12px;
}
