.image-carousel {
  margin-top: 20px;
}

.image-carousel-slide {
  height: 520px;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: 50%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 100%;
}
