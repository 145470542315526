.search-input-container {
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #c5cee0;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  color: #878686;
}

.search-input-container-icon {
  position: absolute;
  top: 7px;
}

.search-input-container-input {
  border: none;
  color: #878686;
}

input[type="search"] {
  -moz-appearance: none; /* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
}
