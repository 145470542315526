.sidebar {
  flex: 1;
  min-width: 200px;
  height: 100%;
  background-color: #ccc;
  border-right-color: silver;
  border-right-style: solid;
  border-right-width: 1px;
}
.sidebar-items {
  height: 60px;
  border-bottom-color: silver;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-item {
  font-size: 16px;
  color: #000;
  text-decoration: none;
}
